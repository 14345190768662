import * as React from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

function Info() {
  return (
    <Container className="info" maxWidth="xl">
      <div className="info-content">
        <div className="text-section">
          <Typography className="title" variant="h6">
            BRIGHTON PSYCH FEST 2025 – FAQ / INFO
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Where
          </Typography>
          <Typography className="text">
            Multi-venue festival taking place across Brighton.
          </Typography>

          <Typography className="text">
            Ticket gains access to all venues. ***
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            When
          </Typography>
          <Typography className="text">Friday 29th August 2025.</Typography>
          <Typography className="text">1pm-3am.</Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Live music line-up
          </Typography>
          <Typography className="text">
            {"Click "} <Link to="/line-up">here</Link>{" "}
            {" to visit line-up A-Z page.*"}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Accessibility
          </Typography>
          <Typography className="text">
            Accessibility information for each venue will be available soon.
          </Typography>
          <Typography className="text">
            For any accessibility enquiries in the meantime, please contact
            info@brightonpsychfest.com.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Tickets
          </Typography>
          <Typography className="text" >
            On sale now **
          </Typography>

          <Typography className="text">
            £30 first release - ON SALE.{" "}
          </Typography>
          <Typography className="text">
            £24.50 early birds - SOLD OUT.{" "}
          </Typography>
          <Typography className="text">
            {"Click "}{" "}
            <Link
              target="_blank"
              to="https://www.seetickets.com/event/brighton-psych-fest-2025/various-brighton-venues/3146372"
            >
              here
            </Link>{" "}
            {" to purchase."}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Wristband Collection x Festival Hub
          </Typography>
          <Typography className="text">
            The Wristband exchange location and information will be announced at
            a later date.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Age restrictions
          </Typography>

          <Typography className="text">
            Age restrictions per venue will be announced at a later date.
          </Typography>
          <Typography className="text">
            Some venues will be 14+. Some will be 18+.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Getting to the festival
          </Typography>
          <Typography className="text">
            The festival will take place across multiple venues to be announced
            soon.
          </Typography>
          <Typography className="text">
            Your wristband gains access to all stages.***
          </Typography>
          <Typography className="text">
            All venues are accessible by foot - parking in Brighton is limited
            so walking or travelling by public transport where possible is
            highly recommended.
          </Typography>
          <Typography className="text">
            Closest Train Station: Queens Rd, Brighton and Hove, Brighton BN1
            3XP
          </Typography>
          <Typography className="text">
            National Express Coach Stop: Brighton Pool Valley Coach Station, BN1
            1NJ
          </Typography>
          <Typography className="text">
            Venues are served by multiple bus routes around the city including:
            1, 1A, 5B, 12X + more - plan your journey using the Brighton and
            Hove Buses route planner.
          </Typography>
          <Typography className="text">
            https://www.buses.co.uk/plan-your-journey
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">* Line-up subject to change</Typography>
          <Typography className="text">** plus booking fees</Typography>
          <Typography className="text">
            *** Venue capacities are based on first come first serve so please
            arrive early for specific acts.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Contact
          </Typography>
          <Typography className="text">info@brightonpsychfest.com</Typography>
        </div>
      </div>
    </Container>
  );
}
export default Info;
