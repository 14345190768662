export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#B9ce6b",
    },
    secondary: {
      main: "#768aff",
    },
    background: {
      default: "#F4e2c1",
    },
    warning: {
      main: "#Ea8181",
    },
    divider: "#Ea8181",
  },
  spacing: 12,
  shape: {
    borderRadius: 0,
  },
};
